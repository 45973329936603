<template>
  <Top></Top>
  <div class="uk-margin-large-top" uk-grid>
    <div class="uk-width-1-3"></div>
    <div class="uk-width-expand@m uk-margin-left uk-margin-right uk-margin-remove-left@m uk-margin-remove-right@m">
      <div class="uk-text-left">
        <p>
          1.
          營養素計算機依據公式計算所需營養素，建議搭配良好運動習慣及每週測量體重，調整您的目標熱量。
        </p>
        <p>
          2. 若有任何問題及建議歡迎來信至
          <a href="mailto:lastsecret628@gmail.com">lastsecret628@gmail.com</a>。
        </p>
        <p>3. 參考網站如下：</p>
        <ul>
          <li>
            <a target="_blank" href="https://ifitness.tw/bmr-and-tdee/"
              >BMR&TDEE計算器</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.womenshealthmag.com/tw/fitness/work-outs/g33307946/introduce-tdee-bmr/"
              >教你計算基礎代謝率、TDEE，學會這5招提升代謝瘦更快</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://trueterral.com/blog/carbon-cycle-diet/"
              >增肌減脂有新招！碳循環飲食怎麼吃？</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.fitme24.com/blog/carbohydro-cycling-diet/"
              >碳水循環法(Carbohydrate Cycling Diet)</a
            >
          </li>
          <li>
            <a target="_blank" href="https://beauty-upgrade.tw/135676/"
              >碳循環飲食法吃起來，增肌減脂簡單get！</a
            >
          </li>
        </ul>
      </div>
      <router-link to="/">
        <button class="uk-button uk-button-secondary uk-margin-medium-top">
          回首頁
        </button>
      </router-link>
    </div>
    <div class="uk-width-1-3"></div>
  </div>
</template>
